<template>
  <div>
    <v-card v-if="isRefundForm && $route.name == `Luo hyvityslasku`" class="mt-2">
      <v-card-title>Perustiedot</v-card-title>

      <v-container>
        <v-row dense>
          <v-col>
            <div v-if="isRefundForm && $route.name == `Luo hyvityslasku`">
              <p>
                Hyvityslasku laskulle nro. <strong> {{ invoice.invoiceNumber }}</strong>
              </p>

              <p>
                Hyvitettävän laskun alkuperäiset saatavat:
                <strong>
                  {{ formatCurrency(invoice.totalAmount) }}
                </strong>
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="invoice.refundInvoiceIds.length > 0" dense>
          <v-col>
            <p class="mb-2 error--text">
              <strong
                >Laskulle on jo luotu {{ invoice.refundInvoiceIds.length }} hyvityslasku(a)</strong
              >
            </p>

            <div v-for="(invoice, idx) in invoice.refundInvoiceIds" :key="idx + 'a'">
              <div class="mb-2">
                <p class="mb-1">
                  <strong>{{ idx + 1 }}. Hyvityslasku {{ invoice.invoiceNumber }}</strong>
                </p>
                <p>
                  Laskun pvm:
                  {{
                    invoice.billDate ? formatDate(invoice.billDate) : formatDate(invoice.createdAt)
                  }}
                </p>
                <p>
                  {{
                    invoice.sendDate ? `Lähetetty ${formatDate(invoice.sendDate)}` : "Ei lähetetty"
                  }}
                </p>
                <p>
                  Yhteensä:
                  <strong>{{ countRefundPrice(invoice.products) }}</strong>
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Vuokrasopimus</v-card-title>

      <v-container>
        <v-row class="contract-box" dense>
          <v-col cols="12" sm="6" md="4">
            <h4 class="mb-2">
              <strong>Sopimustiedot</strong>
            </h4>
            <p class="contract-text">Sopimusnumero</p>
            <p>{{ contract.contractNumber }}</p>

            <p class="contract-text">Vuokranantajat</p>
            <div v-for="landlord in contract.landlords" :key="landlord.name">
              <p>{{ landlord.name }}</p>
            </div>

            <p class="contract-text">Vuokrakohde</p>
            <p>
              {{ contract.apartment.address
              }}<span>{{
                contract.apartment.apartmentNumber ? `, ${contract.apartment.apartmentNumber}` : ""
              }}</span>
            </p>
            <p>{{ contract.apartment.zipCode }}, {{ contract.apartment.city }}</p>

            <div v-if="contract.contractType == 'new'">
              <p class="contract-text">Hinnat</p>
              <p>Vuokra: {{ contract.lease.rent }} €</p>
              <p>Vesimaksu: {{ contract.lease.waterChargePerMonth }} €</p>
              <p>Autopaikka: {{ contract.lease.parkingCost }} €</p>
              <p>Muut kustannukset: {{ contract.lease.otherCosts }}</p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <h4 class="mb-2">
              <strong>Vuokralainen</strong>
            </h4>
            <p class="contract-text">Nimi</p>
            <p>
              {{ contract.tenant.name }}
              <span
                v-if="contract.tenant.revokedDate"
                :class="{ 'warning--text': contract.tenant.revokedDate }"
                >(irtisanottu)</span
              >
            </p>
            <p v-for="(tenant, index) in contract.otherTenants" :key="index + 't'">
              {{ tenant.name }}
              <span v-if="tenant.revokedDate" :class="{ 'warning--text': tenant.revokedDate }"
                >(irtisanottu)</span
              >
            </p>

            <p class="contract-text">Email</p>
            <p>
              {{ contract.tenant.tenantId.email ? contract.tenant.tenantId.email : "Ei emailia" }}
            </p>

            <div v-if="contract.tenant.tenantId.isCompany">
              <p class="contract-text">Y-tunnus</p>
              <p>
                {{
                  contract.tenant.tenantId.businessId
                    ? contract.tenant.tenantId.businessId
                    : "Ei y-tunnusta"
                }}
              </p>
            </div>

            <div v-if="!contract.tenant.tenantId.isCompany">
              <p class="contract-text">Henkilötunnus</p>
              <p>
                {{
                  contract.tenant.tenantId.social
                    ? contract.tenant.tenantId.social
                    : "Ei henkilötunnusta"
                }}
              </p>
            </div>
            <div v-if="contract.tenant.tenantId.isCompany">
              <p class="contract-text">Yritys</p>
              <p>{{ contract.tenant.tenantId.isCompany ? "Kyllä" : "Ei" }}</p>
            </div>
            <div v-if="!contract.tenant.tenantId.isCompany">
              <p class="contract-text">Suomalainen henkilötunnus</p>
              <p>{{ contract.tenant.tenantId.finnishCitizen ? "Kyllä" : "Ei" }}</p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <h4 class="mb-2">
              <strong>Vuokra-aika</strong>
            </h4>
            <p class="contract-text">Vuokrasopimuksen tyyppi</p>
            <p>{{ contract.lease.leaseType }}</p>
            <p class="contract-text">Vuokrasopimuksen alkamispäivä</p>
            <p>{{ formatDate(contract.lease.startDate) }}</p>
            <div v-if="contract.lease.endDate">
              <p class="contract-text">Vuokrasopimuksen päättymispäivä</p>
              <p>{{ formatDate(contract.lease.endDate) }}</p>
            </div>

            <div v-if="contract.contractType == 'new'">
              <div v-if="contract.lease.firstRentDate">
                <p class="contract-text">Vuokranmaksun alkamispäivä</p>
                <p>{{ formatDate(contract.lease.firstRentDate) }}</p>
              </div>
              <p class="contract-text">Vuokranmaksun eräpäivä</p>
              <p>{{ contract.lease.rentDueDate }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],

  props: ["isRefundForm", "invoice", "contract"],

  methods: {
    countRefundPrice(products) {
      let sum = 0;
      products.forEach((el) => (sum += el.amount * el.count * (1 + el.taxpr / 100)));
      return this.formatCurrency(sum);
    },
  },
};
</script>

<style scoped>
p {
  font-size: 14px;
}

.contract-text {
  font-size: 14px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.contract-box {
  max-width: 800px;
}
</style>
